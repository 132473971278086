<template>
  <div class="loginVercodePage">
    <div class="form">
      <div class="item">
        <h4 class="title">手机号/账号</h4>
        <div class="input-view float">
          <a-input v-model:value="mobile" placeholder="请输入手机号/账号" />
        </div>
      </div>
      <div class="item">
        <h4 class="title">图形验证码</h4>
        <div class="input-view">
          <a-input-search
            v-model:value="imageCode"
            placeholder="请输入图形验证码"
            @search="changeCode"
          >
            <template #enterButton>
              <img :src="imageCodeUrl" alt="" />
            </template>
          </a-input-search>
        </div>
      </div>
      <div class="item">
        <h4 class="title">短信验证码</h4>
        <div class="input-view">
          <a-input-search
            v-model:value="smsCode"
            placeholder="请输入图形验证码"
            @search="handleSmsCode"
          >
            <template #enterButton>
              <span v-show="verShow">获取短信验证码</span>
              <span v-show="!verShow">{{ timer }}秒后重新获取</span>
            </template>
          </a-input-search>
        </div>
      </div>
      <a-button
        type="primary"
        :disabled="loginController"
        @click="login"
        :loading="loading"
        >登录</a-button
      >
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      imageCodeUrl: "",
      mobile: "",
      imageCode: "",
      smsCode: "",
      verShow: true,
      timer: 60,
      loading: false,
    };
  },
  mounted() {
    this.changeCode();
  },
  methods: {
    ...mapActions({
      setToken: "user/setToken",
      setUser: "user/setUser",
    }),
    async login1() {
      let res = {
        data: {
          authorization:
            "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI0IiwiZXhwIjoxNjkyODgwNTg3fQ.PZMLmm18ayL5hcYj4CDWQEJZ5n_9f-AWWXp4fSWApt0",
          classInfo: [{ classId: "11", className: "实验班", courseId: "22" }],
          hostipalName: "北京朝阳总医院",
        },
      };
      await this.setToken({
        token: res.data.authorization,
        remember: true,
      });
      await this.setUser({
        classInfo: res.data.classInfo,
        hostipalName: res.data.hostipalName,
      });
      this.goHome();
    },
    async login() {
      if (this.loginController) return;
      const hide = this.$message.loading("登录中..", 0);
      this.loading = true;
      try {
        let res = await this.$http.post(this.$interface.loginMobile, {
          mobile: this.mobile,
          imageCode: this.imageCode,
          smsCode: this.smsCode,
        });
        console.log(res);
        this.$message.success("登录成功");
        await this.setToken({
          token: res.data.data.authorization,
          remember: true,
        });
        await this.setUser({
          classInfo: res.data.data.classInfo,
          hostipalName: res.data.data.hostipalName,
          email: res.data.data.email,
          memberName: res.data.data.memberName,
          mobile: res.data.data.mobile,
          username: res.data.data.username,
          password: res.data.data.password,
        });
        this.goHome();
      } catch (errr) {
      } finally {
        hide();
        this.loading = false;
      }
    },
    goHome() {
      const query = this.$route.query;
      const path = query && query.from ? query.from : "/";
      this.$router.push("/").catch(() => {});
    },
    /* 获取短信验证码 */
    handleSmsCode() {
      if (!this.verShow) return;
      if (this.mobile == "" || this.imageCode == "") {
        this.$message.error("请填写完整");
        return;
      }
      this.postSendSms();
    },
    async postSendSms() {
      try {
        let res = await this.$http.post(
          this.$interface.loginSendSms,
          {
            mobile: this.mobile,
            imageCode: this.imageCode,
          },
          { withCredentials: true }
        );
        if (res.data.code == 0) {
          this.verShow = false;
          var auth_timer = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
              this.verShow = true;
              this.timer = 60;
              clearInterval(auth_timer);
            }
          }, 1000);
        } else {
          this.changeCode();
        }
      } catch (errr) {
        this.changeCode();
      }
    },
    /* 更换图形验证码 */
    async changeCode() {
      try {
        let res = await this.$http.get(this.$interface.loginImageCode, {
          withCredentials: true,
        });
        this.imageCodeUrl = res.data.data || "";
      } catch (err) {}
    },
  },
  computed: {
    loginController() {
      if (this.mobile && this.imageCode && this.smsCode && this.mobile) return false;
      return true;
    },
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>
